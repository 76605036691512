<template>
  <div
    class="text-center align-center"
    style="text-overflow: ellipsis; overflow: hidden"
    :class="Tooltip < 0 ? 'minusPrice' : ''"
  >
    <v-tooltip max-width="300px" color="gray" top>
      <template v-slot:activator="{ on, attrs }">
        <span v-on="on" v-bind="attrs">
          {{ Tooltip }}

          <slot />
        </span>
      </template>
      <span> {{ Tooltip }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    Tooltip: String,
  },
};
</script>
